// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PredictionComponent from './PredictionComponent';
import AstrologyDataComponent from './astrologyData';
import FormComponent from './astrologyForm';

const App = () => {
  const [result, setResult] = useState(null);
  const [predictionHtml, setPredictionHtml] = useState('');

  return (
    <Router>
      <div className="container mx-auto p-4">
        <Routes>
          <Route
            path="/"
            element={
              <FormComponent
                setResult={setResult}
                setPredictionHtml={setPredictionHtml}
              />
            }
          />
          <Route
            path="/prediction"
            element={<PredictionComponent predictionHtml={predictionHtml} />}
          />
          <Route
            path="/astrologydata"
            element={<AstrologyDataComponent result={result} />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
