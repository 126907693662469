// PredictionComponent.js
import React from 'react';

const PredictionComponent = ({ predictionHtml }) => {
  return (
    predictionHtml && (
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Prediction</h2>
        <div dangerouslySetInnerHTML={{ __html: predictionHtml }} />
      </div>
    )
  );
};

export default PredictionComponent;
