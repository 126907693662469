// AstrologyDataComponent.js
import React from 'react';

const AstrologyDataComponent = ({ result }) => {
  return (
    <>
      {result && (
        <>
          <div>
            <h2 className="text-xl font-bold mb-4">Astrological Details</h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <h3 className="text-lg font-bold mb-2">Basic Information</h3>
                {['name', 'date_of_birth', 'time_of_birth', 'place_of_birth', 'latitude', 'longitude'].map((field, index) => (
                  <p className="text-sm" key={index}>
                    {field.replace('_', ' ')}: {result[field]}
                  </p>
                ))}
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">Astrological Details</h3>
                {['ascendant', 'mc', 'ayanamsa', 'node'].map((field, index) => (
                  <p className="text-sm" key={index}>
                    {field.replace('_', ' ')}: {result[field]}
                  </p>
                ))}
              </div>
            </div>
            {Object.keys(result.varga_chart).map((key) => (
              <div key={key} className="mb-8">
                <h3 className="text-lg font-bold mb-2">{key}</h3>
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Planet
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Rashi
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {['Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Rahu', 'Ketu'].map((planet, index) => (
                      <tr key={`${key}-${planet}`}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{planet}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{result.varga_chart[key][index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            {result.bhaava_madya && (
              <div className="mb-8">
                <h3 className="text-lg font-bold mb-2">Bhaava Madya</h3>
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        House
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Rashi
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.entries(result.bhaava_madya).map(([house, rashi]) => (
                      <tr key={`bhaava-${house}`}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{house}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{rashi}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AstrologyDataComponent;
