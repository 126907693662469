// FormComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const GREGORIAN_EPOCH = 1721425.5;

function leap_gregorian(year) {
  return (year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0);
}

function m2j(date_time) {
  const m = date_time.getMonth() + 1;
  const d = date_time.getDate();
  const y = date_time.getFullYear();
  const sec = date_time.getSeconds();
  const min = date_time.getMinutes();
  const hour = date_time.getHours();

  return (GREGORIAN_EPOCH - 1) +
    (365 * (y - 1)) +
    Math.floor((y - 1) / 4) +
    (-Math.floor((y - 1) / 100)) +
    Math.floor((y - 1) / 400) +
    Math.floor((((367 * m) - 362) / 12) +
      ((m <= 2) ? 0 : (leap_gregorian(y) ? -1 : -2)) +
      d) +
    (Math.floor(sec + 60 * (min + 60 * hour) + 0.5) / 86400.0);
}

const FormComponent = ({ setResult, setPredictionHtml }) => {
  const [formData, setFormData] = useState({
    name: '',
    date_of_birth: '',
    time_of_birth: '',
    place_of_birth: '',
    latitude: '',
    longitude: '',
    timezone_offset: 0,
    timezone_offset2: 0,
  });

  useEffect(() => {
    if (formData.date_of_birth) {
      const date = new Date(formData.date_of_birth);
      setFormData((prevFormData) => ({
        ...prevFormData,
        timezone_offset: date.getTimezoneOffset() / 60,
        timezone_offset2: date.getTimezoneOffset(),
      }));
    }
  }, [formData.date_of_birth]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [year, month, day] = formData.date_of_birth.split('-').map(Number);
    const [hours, minutes] = formData.time_of_birth.split(':').map(Number);
    const seconds = 0;

    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    const julianDate = m2j(date) + formData.timezone_offset2 / 1440.0;

    const dataToSend = { ...formData, julian_date: julianDate };

    try {
      const response = await axios.post('http://localhost:5000/api/calculate_sun_moon', dataToSend);
      const data = response.data;
      setResult(data);
      setPredictionHtml(data.prediction);
    } catch (error) {
      console.error('Error fetching astrology data:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {['name', 'date_of_birth', 'time_of_birth', 'place_of_birth', 'latitude', 'longitude'].map((field, index) => (
          <div className="mb-4" key={index}>
            <label className="block text-sm font-medium text-gray-700">{field.replace('_', ' ').toUpperCase()}</label>
            <input
              type={field === 'date_of_birth' ? 'date' : field === 'time_of_birth' ? 'time' : 'text'}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="mt-1 px-3 py-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
              step={field === 'latitude' || field === 'longitude' ? '0.0001' : undefined}
            />
          </div>
        ))}
      </div>
      <div className="text-center">
        <Link to="/astrologydata">
        <button
          type="submit"
          className="mt-4 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Calculate Sun and Moon Data
        </button></Link>
      </div>
    </form>
  );
};

export default FormComponent;
